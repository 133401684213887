<template>
  <b-sidebar id="app-sidebar-menu" v-model="show" backdrop bg-variant="white" no-header shadow>
    <!-- :class="project_id==72?'sub_card_bg':''" -->
    <div class="m-0">
      <component :is="menuType" :menu-tree="menuTree"></component>
    </div>
  </b-sidebar>
</template>

<script>
import store from "@/store";
import {computed, reactive, toRefs} from "@vue/composition-api";
import {routerParams} from "@/libs/utils/routerParams";
import VerticalMenu from "@/views/layout/app-sidebar-menu/components/VerticalMenu";
import TilesMenu from "@/views/layout/app-sidebar-menu/components/TilesMenu";
import ExpandMenu from "@/views/layout/app-sidebar-menu/components/ExpandMenu";

export default {
  name: "AppSidebarMenu",
  components: {
    VerticalMenu,
    TilesMenu,
    ExpandMenu,
  },
  setup(props) {
    /**
     * 优先读取store中的菜单列表
     * 张强 2021-08-09
     */
    const event = reactive({
      show: false,
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      menuTree: computed(() => {
        return menuFormat(store.state.menus.tree);
      }),
      menuType: computed(() => {
        return "ExpandMenu";
      }),
    });
    const menuFormat = (menus_tree = []) => {
      let menus = []
      if (menus_tree.length > 0) {
        menus = [
          {
            title: "总览",
            icon: "",
            menu_id: -99,
            p_menu_id: 0,
            children: menus_tree,
          },
          {
            header: "项目导航",
          },
          ...menus_tree,
          //管理页面
          {
            header: "System Manage",
            menu_id: -1,
          },
          {
            title: "System Settings",
            icon: "SettingsIcon",
            menu_id: -1,
            p_menu_id: 0,
            children: [
              {
                title: "Users Manage",
                route: {
                  name: "admin-users-list",
                  params: {
                    title: "Users Manage",
                    icon: "UserIcon",
                    levels: [
                      {
                        title: "System Manage",
                      },
                    ],
                  },
                },
              },
              {
                title: "Projects Manage",
                route: {
                  name: "admin-projects-list",
                  params: {
                    title: "Projects Manage",
                    icon: "GridIcon",
                    levels: [
                      {
                        title: "System Manage",
                      },
                    ],
                  },
                },
              },
              {
                title: "Menus Manage",
                route: {
                  name: "admin-menus-list",
                  params: {
                    title: "Menus Manage",
                    icon: "MenuIcon",
                    levels: [
                      {
                        title: "System Manage",
                      },
                    ],
                  },
                },
              },
            ],
          },
        ];
      }
      return [
        //前端页面
        // {
        //     title: "Project List",
        //     icon: "MapIcon",
        //     route: "index-project-list",
        // },
        ...menus,
        {
          title: "Account Settings",
          icon: "UserIcon",
          menu_id: -1,
          p_menu_id: 0,
          route: {
            name: "admin-account-setting",
            icon: "UserIcon",
            params: {
              title: "Account Settings",
            },
          },
        },
        /* ...vuexy_demo_navigation,  */
      ];
    };
    // if (event.project_id == 72)
    //   document.body.setAttribute("data-theme1", "primary");
    // else

    return {
      ...toRefs(event),
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
//@import "src/assets/scss/project-jsf/jsf";

#app-sidebar-menu.b-sidebar {
  width: auto;
  overflow: hidden;
}

.navigation-header {
  color: #4b4b4b !important;
  opacity: 0.8;
}

.dark-layout .navigation-header {
  color: #f6f6f6 !important;
}

.dark-layout .bg-light-secondary {
  color: #b4b7bd !important;
}

.btn-flat-primary:hover,
.btn-flat-primary:active,
.btn-flat-primary.active,
.btn-flat-primary:focus {
  color: #1890ff !important;
}

//.dark-layout
//  .main-menu-content
//  .navigation-main
//  .sidebar-group-active
//  .menu-content,
//.dark-layout .main-menu-content .navigation-main .nav-item .menu-content {
//  background-color: transparent !important;
//}

//.data-theme1 #app-sidebar-menu {
//  &.bg-white {
//    @include bg-color-2("sub-card-color");
//  }
//  & .nav-item.open > a {
//    @include bg-color-2("light-bg-color");
//    @include text-2("text-2");
//  }
//}
</style>
